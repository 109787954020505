<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="620px"
  >
    <v-card>
      <v-card-title>
        <v-row align="center">
          <v-col cols="1">
            <v-icon
              size="20"
              @click="dialog = false"
            >
              {{ icons.mdiClose }}
            </v-icon>
          </v-col>

          <v-col align="center">
            <h5>Confirm Deletion</h5>
          </v-col>

          <v-col cols="1"></v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-form @submit.prevent="handleDelete">
        <v-card-text class="py-8 px-8">
          <slot></slot>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-end">
          <v-btn
            outlined
            class="me-3"
            @click="dialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            color="error"
            type="submit"
            :loading="loading"
          >
            Delete
          </v-btn>
        </v-card-actions>
        <v-form>
        </v-form>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiClose } from '@mdi/js'

export default {
  name: 'ConfirmDelete',
  components: {},
  props: {
    value: { type: Boolean, required: false },
    // eslint-disable-next-line vue/require-prop-types
    itemId: { required: true },
    loading: { type: Boolean, required: false },
  },
  setup() {
    const { isDark } = useAppConfig()

    return {
      isDark,
      icons: { mdiClose },
    }
  },
  data() {
    return {}
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    handleDelete() {
      //console.log('delete')
      this.$emit('handle-delete', this.itemId)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>